import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import SubmarineSidebar from "../components/SubmarinePage/SubmarineSidebar"
import CrewSection from "../components/SubmarinePage/CrewSection"
import CapabilitiesSection from "../components/SubmarinePage/CapabilitiesSection"
import FutureSection from "../components/SubmarinePage/FutureSection"
import MissionsSection from "../components/SubmarinePage/MissionsSection"

const IdahoSubmarine = ({ data }) => {
  const page = data.allContentfulAboutUs.edges[0].node

  // const options = {
  //   renderNode: {
  //     [BLOCKS.EMBEDDED_ASSET]: ({
  //       data: {
  //         target: { fields },
  //       },
  //     }) => (
  //       <div
  //         dangerouslySetInnerHTML={{
  //           __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
  //         }}
  //       />
  //     ),

  //     [BLOCKS.EMBEDDED_ENTRY]: node => {
  //       const fields = node.data.target.fields.imageLink
  //       const imgLnk = node.data.target.fields.url
  //       return (
  //         <div
  //           dangerouslySetInnerHTML={{
  //             __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
  //           }}
  //         />
  //       )
  //     },
  //   },
  // }

  return (
    <Layout>
      <SEO title="PCS Idaho Submarine" />
      <Container fluid className="container-fluid-full">
        <PageTitle subPage="PCS Idaho Submarine" pageTitle="PCU Idaho Submarine" link="/">
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <Row>
            <Col sm={8}>
              <div id="leadership" className="jumpTarget">
                <h1>Leadership</h1>
                  <CrewSection />
              </div>
              <hr />

              <div id="sponsor" className="jumpTarget">
                <h1>The Role of a Sponsor</h1>
                <Row>
                  <Col>
                  <p className="blog-text">
                The Secretary of the Navy is charged with designating sponsors for all United States Navy Ships. Sponsors play a unique role for the Navy. As they christen their ships, bestowing upon the vessel a name chosen by the Secretary of the Navy, they become an integral part of that ship’s history. The crew of the ship may change many times over the course of a ship’s life, but the Sponsor remains the same. The Sponsor’s role is an important one. She will participate in as many of the ceremonial milestones in the life of the ship as possible and her experiences will be memorable.</p>
                <p className="blog-text">
A ship sponsor, by tradition, is a female civilian who is invited to "sponsor" a vessel, presumably to bestow good luck and divine protection over the seagoing vessel and all that sail aboard. In the United States Navy and the United States Coast Guard the sponsor is technically considered a permanent member of the ship's crew and is expected to give a part of her personality to the ship, as well as advocate for its continued service and well-being. For passenger ships the sponsor is called a Godmother.
The christening ceremony marks the traditional transition of the ship from the new construction phase to its comprehensive tests and trials phase – moving from a mere hull number to a ship with a name and spirit.
                </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <div className="card-box my-4">
                  <h2 className="text-center my-2">
                    Meet The Ship's Sponsor
                  </h2>
                  <Row>
                    <Col sm={9}>
                      <p>
                    Terry Stackley has lived by the sea, close to the Navy all her life. Her father served as a Navy Communications Technician before taking on a career in naval design, and Terry would spend her childhood years from her birthplace in New Jersey, to Connecticut and Massachusetts before settling in the Baltimore area, moving with her family as her father climbed the ranks at private shipyards in Philadelphia, Groton, Quincy, and Sparrows Point, before he returned to public service with the Naval Sea Systems Command in Washington, DC.</p>
                    <p className="blog-text">Terry graduated from Western Maryland College (now McDaniel College) in 1979, earning her Bachelor's degree in Mathematics and launching her on an early career in computer programming. She married the love of her life, Sean Stackley in 1980, and would dedicate nearly four decades as a Navy wife, at duty stations stretching from San Diego to Boston to Ottawa, Canada to Bath, Maine to Norfolk and finally arriving in Northern Va, where Sean would complete his career in uniform and subsequently serve as the Assistant Secretary of the Navy (Research, Development &amp; Acquisition) from 2008 to 2017. Among her many cherished memories, Terry prizes her role 'pulling the trigger' at Bath Iron Works to launch the USS CURTIS WILBUR (DDG 54).</p>
                    <p>Terry is passionate about music, and after leaving her career as a computer programmer to raise her children she served as the music director for her church for 12 years. In 2009 she founded the Lake Ridge Chorale, an auditioned community choir and nonprofit organization in Northern Virginia, which also serves local charities through concert donations. She is a member of the American Choral Directors Association and sings soprano in the Arlington Chorale, her favorite performance being the annual Messiah Sing-a-long hosted at the John F Kennedy Center for the Performing Arts each year. In her spare time, Terry loves to garden, cook and sew, and serve as hostess, especially for Navy football tailgates and Naval Academy 9th Company reunions.</p>
                    <p>Most recently Terry and Sean have relocated to Annapolis Md. where she loves being a part of the Naval Academy community and a sponsor to Midshipmen. Terry and Sean are blessed with four beautiful children, Erin, Tess, Scott and Maura, and are proud grandparents of their 2 grandchildren, Orson and Callan.</p>
                    <p>Terry is deeply honored and humbled to have been chosen as the sponsor of the USS Idaho.</p>
                    </Col>
                    <Col sm={3}>
                      <Img fluid={data.terryStackley.childImageSharp.fluid} alt="Terry Stackley, USS Idaho Ship's Sponsor" className="rounded" />
                    </Col>
                  </Row>
                </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h2 className="text-center">
                      Ceremonial Milestones
                    </h2>
                    <h3>Keel Laying Ceremony</h3>
                    <p>During this ceremony, the Sponsor etches her initials into the keel plate to verify that <strong>"This keel has been truly and fairly laid".</strong></p>
                    <h3>Christening</h3>
                    <p>The Sponsor bestows the ship’s name while smashing the bottle against the bow of the ship.</p>
                    <h3>Commissioning</h3>
                    <p>The commissioning ceremony is one of the most important. The ship is accepted by the United States Navy and becomes part of the active Navy Fleet. When the Sponsor says, "Man Our Ship and Bring Her to Life" and the crew boards the ship, all present rejoice and break out in thunderous applause. It is a very patriotic and proud moment for all.</p>
                    <h3>Relationship with the Ship and Crew</h3>
                    <p>Far beyond participation in ceremonial milestones, Sponsorship represents a lifelong relationship with the ship and her crew. While this bond begins with the ship’s christening and the initial (plankowner) crew, it will ideally extend throughout the ship’s service life and even beyond. Sponsors are encouraged to make every effort to foster this special relationship, and to maintain contact with the initial and successive captains and the amazing men and women who comprise her crew.</p>
                    <p>For more information about ship's sponsors please visit <a href="http://societyofsponsorsofusn.org" target="_blank" rel="noopener noreferrer">Society of Sponsors of the United States Navy</a>.</p>
                  </Col>
                </Row>

              </div>
<hr />
              <div id="crew" className="jumpTarget">
                <h1>Crew</h1>
                <p className="blog-text">
                    The crew will eventually consist of approximately 120 enlisted personnel and 14 officers. The crew will be mixed gender throughout many of the ranks. As of September 2020 the crew consists of approximately 35 sailors. Most of their current activities revolve around program development, training and qualifications in preparation for operating the future submarine. </p>
                    <p>New crew members are reporting aboard weekly.</p>
                    <p>Crew members that are Idaho Natives:</p>
              
                
                <Row>
                <Col sm={2}>
                    <Img fluid={data.andrewLeonhardt.childImageSharp.fluid} alt="Electricians Mate Nuclear First class (ss) Andew Leonhardt" className="rounded" />
                  </Col>
                  <Col sm={10}>
                    <p><span style={{fontSize: 'larger', fontWeight: 'bold'}}>Electricians Mate Nuclear First Class (ss) Andrew Leonhardt</span>
                      <br />
                      Hometown: Nampa, ID<br />
                      High School / Graduation Year: Meridian High School 2010<br />
                      University: N/A<br />
                      Favorite things to do in Idaho: Fishing, Camping, Floating the Boise River </p>
                    </Col>
                    </Row>
                    <Row>
                <Col sm={2}>
                    <Img fluid={data.boscan.childImageSharp.fluid} alt="SSTSSN BOSCAN" className="rounded" />
                  </Col>
                  <Col sm={10}>
                    <p><span style={{fontSize: 'larger', fontWeight: 'bold'}}>STS3 Robert Boscan
</span>
                      <br />
                      Hometown: Hayden Idaho<br />
                      High School:Coeur d’Alene High School
<br />
                      Favorite things to do in Idaho: <br />Winter – Skiing at Silver Mountain
                      <br />Summer – Silver Wood theme park
</p>
                    </Col>
                    </Row>
                    {/* <Row>
                    <Col sm={2}>
                    <Img fluid={data.daltonJones.childImageSharp.fluid} alt="Chief Machinist Mate Nuclear (ss) Dalton Jones" className="rounded" />
                  </Col>
                  <Col sm={10}>
                    <p><span style={{fontSize: 'larger', fontWeight: 'bold'}}>Chief Machinist Mate Nuclear (ss) Dalton Jones</span><br />
                    Hometown: Cascade, ID<br />
    High School: Cuyama Valley High School 2011<br />Favorite thing to do in Idaho: Dirtbike, offroad, camp, fish, go to lake Cascade. 
                    </p>
                  </Col>
                </Row> */}

              </div>
<hr />
              <div id="ships-crest" className="jumpTarget">
                <h1>Crest</h1>
                <Row>
                  <Col md={8}>
                  
                  <div>
                  <p>The Crest of the USS IDAHO, has many features that ties the boat to its namesake state.</p>
                  <p>At the top of the crest can be seen a large cut opal, containing a vista of the sawtooth mountain range, with a grouping of white pine trees (the official state tree).</p>
                  <p>Behind the opal is an Appaloosa horse, the 1st of several Native American ties to Idaho and the official state horse.</p>
                  
                  <p>In front of the appaloosa is the motto of both USS IDAHO and the state of Idaho, "ESTO PERPETUA" or "let it be perpetual". Which is especially true for a nuclear-powered warship.</p>
                  
                  <p>Below the motto are an arraignment of five feathers. These represent the five different Native American tribes that call Idaho home.</p>
                  
                  <p>The outline of the state can be seen, flanked by two steelhead trout. One gold and one silver (representing the officers and enlisted uniform devices respectively) with green (jade) and red (garnet) eyes. These are in reference to both the nickname of Idaho as the gem state and also reference the running lights on the submarine.Above and below each fish can be seen a stylized wave of water, this is to represent their journey to and from the ocean.</p>
                  
                  <p>Within the state outline one can see the namesake itself, USS IDAHO, steaming from its acoustic birthplace, Acoustic Research Detachment (ARD) Bayview, ID, the US Navy’s premier acoustic research laboratory. (denoted by an anchor on the southern end of lake Pend Oreille. The Sub has a red rudder protruding from the water to serve as reference to LSV-2 Cutthroat, the quarter scale Virginia Class unmanned research model, operated at ARD. Just to the right of the sub’s conning tower is a reference to NPTU Idaho with a design made from an element of their unit patch. Until recently the majority of the US Navy's nuclear operators were trained here. Leading the sub into her mission swiftly and silently, is a peregrine falcon (the state raptor). Below the falcon are 4 silvers stars, one for each of the USS IDAHOs, and below them in silhouette is the most recent USS IDAHO, BB42.</p>
                  
                  <p>The lower portion of the crest features the name "USS IDAHO" and "SSN 799" over a red, white and blue field. Bordering this to the left is a grouping of huckleberries (the state fruit) and to the right, syringas (the state flower).</p>
                  
                  <h3>About the designer</h3>
                  <p>The crest was designed by Mr. Jason Watson of the Naval Nuclear Laboratory. With his other duties he has had the pleasure of designing 9 other Virginia class submarine crests. He resides in Ballston Spa, with his wife Katelyn and his daughter Sophie.</p>
                  </div>
                  </Col>
                  <Col md={4}>
                  <div style={{width: '400px'}} className="text-center">
                  <Img fluid={data.idahoCrest.childImageSharp.fluid} alt="USS IDAHO 799 Crest" />
                  </div>
                  </Col>
                </Row>

               

              </div>

              <hr />
              <div id="the-boat" className="jumpTarget">
                <h1 id="boat-capabilities" className="jumpTarget">The Boat</h1>
                <h2 className="text-center">Capabilities</h2>
                <CapabilitiesSection />
                <div id="features" className="jumpTarget">
                <FutureSection />
              </div>
              <hr />

                <h1 id="boat-mission" className="text-center jumpTarget my-4">Future Missions of the USS IDAHO SSN 799</h1>
                <MissionsSection />
              </div>
      
              
            </Col>
            <Col sm={4}>
              <SubmarineSidebar />
            </Col>
          </Row>
        </ContentBlock>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulAboutUs {
      edges {
        node {
          childContentfulAboutUsVisionMissionRichTextNode {
            json
          }
          advisory: childContentfulAboutUsAdvisoryBoardRichTextNode {
            json
          }
          commissioning: childContentfulAboutUsCommissioningCommitteeRichTextNode {
            json
          }
          commissioning: childContentfulAboutUsCommissioningCommitteeRichTextNode {
            json
          }
          endorsements: childContentfulAboutUsEndorsementsRichTextNode {
            json
          }
        }
      }
    }
   terryStackley: file(relativePath:{eq: "PhotoTerryStackley.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    idahoCrest: file(relativePath: {eq: "USS-IDAHO-Crest.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    treavorElison: file(relativePath: {eq: "TreavorElison.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    andrewLeonhardt: file(relativePath: {eq: "AndrewLeonhardt.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    daltonJones: file(relativePath: {eq: "DaltonJones.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    davidDrury: file(relativePath: {eq: "David-Drury.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    boscan: file(relativePath: {eq: "Boscan.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IdahoSubmarine
